<template>
  <div class="home-con">
    <van-nav-bar
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.code" :title="item.code" 
        :label="item.label" :value="item.timeLimitStr" />
      <van-cell v-for="item in array_error" :key="item.code" :title="item.code" 
        :label="item.msg">
        <template #title>
          <span style="color: #f44;">{{ item.code }} </span>
          <van-tag type="danger">无效</van-tag>
        </template>
      </van-cell>

    </van-list>
    <div style="margin: 16px;">
      <van-button round block type="info" :loading="btnLoaing" loading-text="加载中..." @click="handleReplaceOk" :disabled="list.length===0">确认换卡</van-button>
    </div> 
    <div style="margin: 16px;" v-if="admin_level > 3">
      <van-button round block type="info" @click="handleDeleteOk" :loading="btnLoaing" :disabled="list.length===0">仅删除</van-button>
    </div> 
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'Home',
  data(){
    return {
      list: [],
      array_error: [],
      codes: this.$route.params.codes,
      loading: false,
      finished: false,
      finishedText:"",
      only_delete:0,
      btnLoaing:false,
    }
  },
  computed:{
    admin_level(){
      return this.$store.state.user.admin_level
    }
  },
  components: {

  },
  methods:{
    handleDeleteOk(){
      this.only_delete = 1;
      this.btnLoaing = true;
      let msg = '确认仅删除？确认后激活码将永久删除，激活码激活的账号及账号数据会被永久删除,账号用户将不能使用软件。';
      Dialog.confirm({
        title: '注意',
        message: msg,
      }).then(() => {
          api.replace_code.replaceAndGenCode({only_delete:this.only_delete, codes:this.codes, code_info:{ok:this.list,error:this.array_error}}).then(res=>{
            let r = res.data;
            if(r.code == 1){
             this.$toast('仅删除成功')
             this.$router.push({name:'replace_success',params:{codes:r.data.new_codes, total:r.data.new_codes_count}})
            }else{
              this.$toast(r.msg)
            }
            this.btnLoaing = false;
            // this.$router.push({name:'replace_code_info',params:{codes:this.$route.params.codes}})
          }).catch(err=>{
            this.$toast('请求失败')
            this.btnLoaing = false;
          })
      }).catch(() => {
        // on cancel
        this.$toast('取消')
        this.btnLoaing = false;
      });
    },
    handleReplaceOk(){
      this.btnLoaing = true;
      let msg = '确认换卡？确认后激活码将永久删除，激活码激活的账号及账号数据会被永久删除,账号用户将不能使用软件。成功后为你生成同类型的新激活码';
      Dialog.confirm({
        title: '注意',
        message: msg,
      }).then(() => {
          api.replace_code.replaceAndGenCode({codes:this.codes, code_info:{ok:this.list,error:this.array_error}}).then(res=>{
            let r = res.data;
            if(r.code == 1){
              this.$toast('换卡成功')
              console.log(r.data)
              //清理输入的代替换激活码
              this.$router.push({name:'replace_success',params:{codes:r.data.new_codes, total:r.data.new_codes_count}})
            }else{
              this.$toast(r.msg)
            }
            this.btnLoaing = false;
            // this.$router.push({name:'replace_code_info',params:{codes:this.$route.params.codes}})
          }).catch(err=>{
            //换卡失败提醒
            this.$toast('换卡失败,请联系客服'+err)
            this.btnLoaing = false;
          })
          
      }).catch(() => {
        this.$toast('取消')
        this.btnLoaing = false;
        // on cancel
      });
    },
    onClickLeft(){
      this.$router.push({name:'replace_key_index'})
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    onLoad() {
      this.loading = true
      //获取route参数
      let codes = this.$route.params.codes;
      this.codes = codes;

      //如果激活码为空跳转到index
      if(!codes || codes.length==0){
        this.$router.push({name:'replace_key_index'})
        return;
      }

      api.replace_code.codeinfo({codes}).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            this.list = r.data.code_info;
            this.array_error = r.data.array_error;
            let total_code_num = this.list.length??0;

            this.list = this.list.map(item=>{
              //是否使用
              let useStr = item.is_used == 1 ? '已使用' : '未使用';
              //用户名
              let username = item.username ? item.username : '';
              //使用时间
              let usedTimeStr = item.used_time_str ? item.used_time_str : '';

              item.label = item.code_type_name +" " +useStr +" "+ username +" "+usedTimeStr;

              return item
            })

            this.finishedText = '共'+total_code_num+'个码可以替换';
            this.finished = true
          }else{
            Dialog.alert({
              message: r.msg,
              theme: 'round-button',
            }).then(() => {
              // on close
              this.finished = true
              this.loading = false
              this.$router.go(-1)
            });
          }
      }).catch(err=>{
        Toast.fail('获取失败'+err);
      })
    },
  },
  mounted(){

  }
}
</script>
<style lang="scss" scoped>
.home-con{
  // .cell-title{
  //   text-align: left;
  // }

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
</style>